import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { CheckLoginStatus } from "../service/auth";

export const AdminRoute = ({ component: Component, ...rest }) => {
  const [authState, setAuthState] = useState({
    isLogin: false,
    isAdmin: false,
    isMounted: false,
  });

  const checkAuth = () => {
    CheckLoginStatus()
      .then((res) => {
        console.log(res.data, "response data here");
        setAuthState({
          isLogin: true,
          isAdmin: res.data.role === "admin",
          isMounted: true,
        });
      })
      .catch((err) => {
        setAuthState({
          isLogin: false,
          isAdmin: false,
          isMounted: true,
        });
        console.log(err);
      });
  };

  useEffect(() => {
    checkAuth();
  }, []);

  if (!authState.isMounted) {
    return null; // or loading spinner
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        authState.isLogin && authState.isAdmin ? (
          <Component {...props} />
        ) : authState.isLogin ? (
          <Redirect to="/unauthorized" />
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
