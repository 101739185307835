import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../layout";

import Dashboard from "../view/dashboard/Dashboard";
import Message from "../view/dashboard/message";
import AddMessage from "../view/dashboard/message/addMessage";
import ReceiverList from "../view/dashboard/receivers";
import AddReceiver from "../view/dashboard/receivers/AddReceiver";
import Gatway from "../view/dashboard/gateway/index";
import AddGateway from "../view/dashboard/gateway/addGateway";
import ApikeyList from "../view/dashboard/key";
import AddAPIKey from "../view/dashboard/key/AddKey";
import Users from "../view/dashboard/account/user/index";
import AddUser from "../view/dashboard/account/user/addUser";
import Admins from "../view/dashboard/account/admin/index";
import AddAdmin from "../view/dashboard/account/admin/addAdmin";
import CompanyList from "../view/dashboard/company";
import CompanyAdd from "../view/dashboard/company/AddCompany";
import EDMHeaderFooter from "../view/dashboard/settings/EDMheaderFooter";
import AddEDMHeaderFooter from "../view/dashboard/settings/EDMheaderFooter/AddEDMHeaderFooter";
import { AdminRoute } from "./AdminRoute";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/message" />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/message" component={Message} />
        <Route exact path="/edm" component={AddMessage} />
        <Route exact path="/message/add" component={AddMessage} />
        <Route exact path="/message/clone/:grpId" component={AddMessage} />
        <Route exact path="/message/update/:id" component={AddMessage} />
        <Route exact path="/receivers" component={ReceiverList} />
        <Route exact path="/receivers/add" component={AddReceiver} />
        <Route exact path="/receivers/update/:id" component={AddReceiver} />
        <Route exact path="/gateway" component={Gatway} />
        <Route exact path="/gateway/add" component={AddGateway} />
        <Route exact path="/gateway/update/:id" component={AddGateway} />
        <Route exact path="/key" component={ApikeyList} />
        <Route exact path="/key/add" component={AddAPIKey} />
        <Route exact path="/key/update/:id" component={AddAPIKey} />
        <AdminRoute exact path="/account/user" component={Users} />
        <AdminRoute exact path="/account/user/add" component={AddUser} />
        <AdminRoute exact path="/account/user/update/:id" component={AddUser} />
        <AdminRoute exact path="/account/admin" component={Admins} />
        <AdminRoute exact path="/account/admin/add" component={AddAdmin} />
        <AdminRoute
          exact
          path="/account/admin/update/:id"
          component={AddAdmin}
        />
        <Route exact path="/company" component={CompanyList} />
        <Route exact path="/company/add" component={CompanyAdd} />
        <Route exact path="/company/update/:id" component={CompanyAdd} />
        <Route
          exact
          path="/setting/edm-header-footer/"
          component={EDMHeaderFooter}
        />
        <Route
          exact
          path="/setting/edm-header-footer/add"
          component={AddEDMHeaderFooter}
        />
        <Route
          exact
          path="/setting/edm-header-footer/update/:id"
          component={AddEDMHeaderFooter}
        />
      </Switch>
    </Suspense>
  );
}
