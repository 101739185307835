import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "../view/auth/login";
import RedirectPage from "../view/Redirect";
import UnsubscribeEDM from "../view/Unsubscribe";
import BasePage from "./BasePage";
import { Layout } from "../layout";
import { PrivateRoute } from "./private";
import Unauthorized from "./unauthorized";

// Update your main Routes component (index.js)
export function Routes() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/auth/login" component={Login} />
          <Route exact path="/redirect" component={RedirectPage} />
          <Route exact path="/unsubscribe" component={UnsubscribeEDM} />
          <Route exact path="/unauthorized" component={Unauthorized} />
          <Redirect from="/auth" to="/auth/login" />
          <Layout>
            <PrivateRoute path="/" component={BasePage} />
          </Layout>
        </Switch>
      </Router>
    </>
  );
}
